

@media (max-width: 2000px) {
    .left-sidebar {
        max-width: 400px;
    }
    .left-sidebar .sidebar-header {
        margin-bottom: 30px;
    }
    .left-sidebar .social-profile {
        margin-bottom: 30px;
    }
    .left-sidebar .copyright {
        margin-bottom: 25px;
    }

    .menu {
        top: 50%;
        transform: translateY(-50%);
    }
}

@media (max-width: 1555px) {
    .left-sidebar {
        max-width: 350px;
        padding: 30px;
    }
    .left-sidebar > img.me {
        width: 100%;
        max-width: 250px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 46px;
    }
    .left-sidebar .sidebar-header .designation {
        line-height: 1.4;
    }
}

@media (max-width: 1220px) {
    .custom-container {
        max-width: 767px;
    }
    .content-width {
        max-width: 100%;
    }
    .section-header h1 br {
        display: none;
    }
    .left-sidebar {
        position: relative;
        max-width: 767px;
        left: 0;
        margin: auto;
        top: 0;
        transform: translateY(0);
        margin-top: 20px;
        display: none;
    }

    .drake-main .left-sidebar {
        display: block;
    }
    .left-sidebar > img.me {
        width: auto;
    }
    .icon-menu {
        position: fixed;
    }
    .menu {
        display: none !important;
    }
    .responsive-sidebar-menu .sidebar-menu-inner .menu-wrap .menu {
        display: flex !important;
    }
    .resume-area,
    .about-area {
        padding-bottom: 50px;
    }
    .about-area .about-content p {
        max-width: 100%;
    }
    .testimonial-area {
        padding-top: 50px;
    }

    .services-items {
        display: flex;
        gap: 15px;
    }
    .services-items .service-item {
        margin-bottom: 0;
        padding: 30px 30px 30px 30px;
        width: 33.3333%;
    }
    .services-items .service-item h2 {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .services-items .service-item p {
        margin-bottom: 25px;
    }
    .services-items .service-item i {
        font-size: 28px;
        top: 25px;
        right: 20px;
    }
    .pricing-table-items .pricing-table .theme-btn {
        padding: 11px 40px 10px 40px;
    }
}
@media (max-width: 977px) {
    .blog-three-columns-main .blog-content-area .row > .col-md-4 {
        width: 50%;
    }
}
@media (max-width: 787px) {
    .hero-section .hero-content h1 {
        font-size: 58px;
        line-height: 1.3;
    }
    .hero-section .hero-content .facts h1 {
        font-size: 52px;
    }
}

@media (max-width: 767px) {
    .icon-menu {
        right: 30px;
        top: 30px;
    }
    .hero-section .hero-content .go-to-project-btn {
        margin-left: 0;
    }
    .skills-area .skills-content .row .col-md-3 {
        flex: 0 0 auto;
        width: 50%;
    }
    .clients-logos .row .col-md-3 {
        flex: 0 0 auto;
        width: 33.3333%;
        margin-bottom: 54px;
    }
    .pricing-area {
        padding-top: 50px;
    }
    .pricing-table-items .pricing-table {
        margin-bottom: 15px;
        display: block;
        height: auto;
    }
    .pricing-table-items .pricing-table .theme-btn {
        width: 100%;
        padding: 13px 20px;
    }
    .pricing-table-items .pricing-table ul {
        margin-bottom: 50px;
    }
    .contact-area .contact-content h3 {
        margin-bottom: 50px;
    }
    .header-area .custom-container .row .col-sm-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .header-area .header-right .show-menu-toggle  {
        display: flex;
    }
    .header-area .header-right nav {
        position: fixed;
        right: 0;
        max-width: 350px;
        width: 100%;
        height: 100%;
        z-index: 100;
        background: #1f1f1f;
        padding: 60px 40px 40px 40px;
        overflow-x: hidden;
        top: 0;
        margin-right: -300px;
        opacity: 0;
        visibility: hidden;
    }
    .header-area .header-right nav.active {
        opacity: 1;
        margin-right: 0;
        visibility: visible;
    }
    .header-area nav .nav-menu li + li {
        margin-top: 10px;
    }
    .header-area .header-right nav .nav-menu {
        display: block;
    }
    .header-area nav .close-menu {
        display: block;
    }
    .header-area nav .nav-menu li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .header-area nav .nav-menu li a {
        flex: 1;
    }
    .header-area nav .nav-menu li .dropdown {
        position: relative;
        visibility: visible;
        opacity: 1;
        width: 100%;
        display: none;
    }
    .header-area nav .nav-menu li .dropdown li a {
        font-size: 14px;
    }
    .header-area nav .nav-menu li .dropdown li {
        margin: 0;
    }
    .header-area nav .nav-menu li .toggle-sub-dropdown,
    .header-area nav .nav-menu li .toggle-dropdown {
        display: block;
    }
    .header-area nav .nav-menu li .dropdown li .dropdown {
        padding-left: 15px;
        left: 0;
    }
    .header-area nav .nav-menu li.active > .dropdown {
        display: block;
    }
    .global-color .inner .global-color-option h2 {
        margin-bottom: 60px;
    }
    .global-color .inner .global-color-option {
        padding: 100px 50px;
    }
    .global-color .global-color-option .color-boxed {
        margin-bottom: 50px;
        padding-top: 10px;
    }
    .global-color .inner .global-color-option .global-color-option-inner .themes {
        padding-top: 10px;
    }
    .global-color .inner .global-color-option .global-color-option-inner .themes li {
        margin-right: 40px;
        margin-bottom: 20px;
    }
    .services-items {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .services-items .service-item {
        width: 100%;
    }

    .blog-sidebar {
        margin-top: 50px;
    }
    .blog-three-columns-main .blog-content-area .row > .col-md-4 {
        width: 100%;
    }
    .footer-area {
        padding-bottom: 60px;
    }
    .footer-area .footer-widget-item {
        margin-bottom: 40px;
    }
}
@media (max-width: 584px) {
    .subtitle {
        margin-bottom: 40px;
    }
    .section-header h1 {
        font-size: 38px;
        line-height: 1.4;
    }
    .skills-area,
    .services-area,
    .resume-area,
    .about-area,
    .hero-section {
        padding-bottom: 0;
    }
    .hero-section .hero-content h1 {
        font-size: 50px;
    }
    .hero-section .hero-content .facts h1 {
        font-size: 48px;
        margin-bottom: 25px;
    }
    .hero-section .hero-content .facts p br {
        display: none;
    }
    .hero-section .hero-content .facts {
        gap: 20px;
    }
    .resume-area .resume-content .resume-timeline .item {
        padding-left: 50px;
    }
    .services-items {
        grid-template-columns: 1fr;
    }
    .portfolio-area {
        padding-top: 40px;
    }
    .clients-logos .row .col-md-3 {
        width: 50%;
    }
    .contact-area  {
        padding-bottom: 80px;
    }

    .breadcrumb-area .breadcrumb-content h1 {
        font-size: 30px;
        margin-bottom: 15px;
    }

    .comments-area .comments .comments-list .comment-item .comment-body {
        gap: 15px;
    }
    .comments-area .comments .comments-list .comment-item .comment-body .comment-avatar {
        width: 60px;
        height: 60px;
    }
    .comments-area .comments .comments-list .comment-item .comment-body .comment-content h4 {
        margin-bottom: 15px;
    }
    .comments-area .comments .children {
        padding-left: 40px;
    }
}
@media (max-width: 425px) {
    .comments-area .comments .comments-list .comment-item .comment-body .comment-content h4 {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .comments-area .comments .comments-list .comment-item .comment-body .comment-content h4 .date {
        margin-left: 26px;
        margin-top: 10px;
    }
}
